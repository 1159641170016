import CodeTest from '@/components/task-templates/CodeTest'

export default [
  {
    component: CodeTest,
    lang: 'js',
    name: 'Вывод стека',
    text: 'Функция \\(printStructure\\). Дан стек \\(a\\). Выведите все его элементы (каждый на новой строке)',
    note: null,
    examples: [
      {
        input: `
          printStructure({
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null
                      }
                  }
              }
          })
          console.log('---')
          printStructure({
              head: {
                  value: 4,
                  next: {
                      value: 3,
                      next: {
                          value: 2,
                          next: {
                              value: 1,
                              next: null
                          }
                      }
                  }
              }
          })
          console.log('---')
          printStructure({
              head: null
          })
        `,
        output: `
          10
          20
          30
          ---
          4
          3
          2
          1
          ---
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в стек',
    text: 'Функция \\(stackPush\\). Дан стек \\(a\\) и число \\(x\\). Добавьте в стек \\(x\\) первым элементом',
    note: null,
    examples: [
      {
        input: `
          const stack1 = {
              head: {
                  value: 20,
                  next: {
                      value: 30,
                      next: null
                  }
              }
          }
          stackPush(stack1, 10)
          printStructure(stack1)
          console.log('---')
          const stack2 = {
              head: {
                  value: 3,
                  next: {
                      value: 2,
                      next: {
                          value: 1,
                          next: null
                      }
                  }
              }
          }
          stackPush(stack2, 4)
          printStructure(stack2)
          console.log('---')
          const stack3 = {
              head: null
          }
          stackPush(stack3, 'first')
          printStructure(stack3)
        `,
        output: `
          10
          20
          30
          ---
          4
          3
          2
          1
          ---
          first
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Удаление из стека',
    text: 'Функция \\(stackPop\\). Дан стек \\(a\\). Функция должна удалить из стека первый элемент и вернуть его. Если дан пустой стек, вернуть null',
    note: null,
    examples: [
      {
        input: `
          const stack1 = {
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null
                      }
                  }
              }
          }
          console.log(stackPop(stack1), 'удалено')
          printStructure(stack1)
          console.log('---')
          const stack2 = {
              head: {
                  value: 4,
                  next: {
                      value: 3,
                      next: {
                          value: 2,
                          next: {
                              value: 1,
                              next: null
                          }
                      }
                  }
              }
          }
          console.log(stackPop(stack2), 'удалено')
          printStructure(stack2)
          console.log('---')
          const stack3 = {
              head: null
          }
          console.log(stackPop(stack3))
        `,
        output: `
          10 удалено
          20
          30
          ---
          4 удалено
          3
          2
          1
          ---
          null
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Создание стека',
    text: 'Функция \\(createStack\\). Функция ничего не принимает, создаёт пустой стек и возвращает его, добавив кроме head: null также методы \\(push\\) и \\(pop\\) (для добавления в начало и удаления из начала). Функция printStructure всё ещё должна работать правильно на объектах, возвращённых функцией \\(createStack\\)',
    note: null,
    examples: [
      {
        input: `
          const stack1 = createStack()
          stack1.push(10)
          stack1.push(20)
          stack1.push(30)
          printStructure(stack1)
          console.log('---')
          console.log(stack1.pop(), 'удалено')
          console.log(stack1.pop(), 'удалено')
          stack1.push(40)
          printStructure(stack1)
        `,
        output: `
          30
          20
          10
          ---
          30 удалено
          20 удалено
          40
          10
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление функциональности итератора',
    text: 'Нужно изменить функцию \\(createStack\\), добавив в возвращаемый объект метод [Symbol.iterator]',
    note: null,
    examples: [
      {
        input: `
          const stack1 = createStack()
          stack1.push(10)
          stack1.push(20)
          stack1.push(30)
          console.log(...stack1)
          let sum = 0
          for (const x of stack1) {
              sum += x
          }
          console.log(sum)
        `,
        output: `
          30 20 10
          60
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сборка и разборка изделия',
    text: 'Функция \\(makingProduct\\). Дана последовательность установки деталей в изделие (последовательность из строк, генератор последовательностей нужно взять из темы циклы). Выведите последовательность разборки этого изделия (порядок противоположный), используя \\(printStructure\\)',
    note: null,
    examples: [
      {
        input: `
          makingProduct(seq(
            'Материнская плата',
            'Процессор',
            'Жесткий диск',
            'Блок питания',
            'Оперативная память',
            'Кулер',
            'Видеокарта'
          ))
        `,
        output: `
          Видеокарта
          Кулер
          Оперативная память
          Блок питания
          Жесткий диск
          Процессор
          Материнская плата
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'История изменений',
    text: "Функция \\(changeHistory\\). Дана последовательность из строк. Каждая строка олицетворяет какое-то действие пользователя. Если строка начинается с '+' - это новое действие, если строка равна '-' - это отмена последнего действия. Выведите все действия, которые не были отменены, в обратном порядке",
    note: null,
    examples: [
      {
        input: `
          changeHistory(seq('+A', '-', '+B', '+C'))
          changeHistory(seq('+A', '+B', '+C', '-', '+D', '-', '-', '+E'))
          changeHistory(seq('+A', '+B', '+C', '-', '-', '-'))
        `,
        output: `
          C B
          E A
          
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Конференция',
    text: "Функция \\(isCorrectConference\\). На некой конференции обсуждают вопросы. По правилам конференции, во время обсуждений можно начать новый вопрос в любой момент, но закрывать текущий вопрос можно только если закрыты все вопросы, открытые в момент обсуждения текущего вопроса. В конце конференции все вопросы должны быть закрыты. Проверьте, проведена ли данная конференция по правилам. Дана последовательность из строк, начинающихся с '+' (открыть вопрос) или '-' (закрыть вопрос), за которыми идёт обозначение вопроса. Функция должна вернуть true, если правила соблюдены, либо false, если нарушены",
    note: null,
    examples: [
      {
        input: `
          console.log(isCorrectConference(seq()))
          console.log(isCorrectConference(seq('+A', '-A')))
          console.log(isCorrectConference(seq('+A', '+B', '-B', '+C', '-C', '-A')))
          console.log(isCorrectConference(seq('+A', '+A', '-A', '-A', '+A', '-A')))
          console.log(isCorrectConference(seq('+A')))
          console.log(isCorrectConference(seq('-A', '+A')))
          console.log(isCorrectConference(seq('+A', '+B', '+C', '-B', '-C', '-A')))
          console.log(isCorrectConference(seq('+A', '+B', '+C', '+C', '-B', '-A')))
        `,
        output: `
          true
          true
          true
          true
          false
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Правильная скобочная последовательность',
    text: 'Функция \\(correctBracketsSeq\\). Дана строка, содержащая скобки разных типов: (), [], {}. Определите, является ли данная скобочная последовательность правильной. Для этого должны выполняться условия: каждая закрывающая скобка должна соответствовать по типу последней незакрытой открывающей, закрывающая скобка должна идти строго после своей открывающей, в конце все открытые скобки должны быть закрыты',
    note: null,
    examples: [
      {
        input: `
          console.log(correctBracketsSeq(''))
          console.log(correctBracketsSeq('()[]{}'))
          console.log(correctBracketsSeq('([{}])'))
          console.log(correctBracketsSeq('([]{})'))
          console.log(correctBracketsSeq('[()({}){}]'))
          console.log(correctBracketsSeq(')('))
          console.log(correctBracketsSeq('[(])'))
          console.log(correctBracketsSeq('{{{}}}}'))
        `,
        output: `
          true
          true
          true
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в конец стека',
    text: 'В функции \\(createStack\\) добавьте к объекту метод \\(pushLast\\). Метод принимает число \\(x\\) и добавляет его в конец стека, ничего не возвращает',
    note: null,
    examples: [
      {
        input: `
          const stack1 = createStack()
          stack1.pushLast(10)
          stack1.push(20)
          stack1.pushLast(30)
          console.log(...stack1)
          const stack2 = createStack()
          stack2.push(10)
          stack2.pushLast(20)
          stack2.push(30)
          console.log(...stack2)
        `,
        output: `
          20 10 30
          30 10 20
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Удаление из конца стека',
    text: 'Измените функцию \\(createStack\\), добавив метод \\(popLast\\), который удаляет последний элемент из стека и возвращает его значение. Если стек пустой, нужно вернуть null',
    note: null,
    examples: [
      {
        input: `
          const stack1 = createStack()
          console.log(stack1.popLast())
          stack1.push(10)
          stack1.pushLast(20)
          stack1.push(30)
          console.log(stack1.popLast())
          console.log(stack1.popLast())
          console.log(stack1.popLast())
          console.log(stack1.popLast())
        `,
        output: `
          null
          20
          10
          30
          null
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в очередь',
    text: 'Функция \\(queuePush\\). Дана очередь \\(a\\) и значение \\(x\\). Добавьте в конец очереди значение \\(x\\), функция ничего не возвращает',
    note: null,
    examples: [
      {
        input: `
          const queue1 = {
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null
                      }
                  }
              },
              tail: null
          }
          queue1.tail = queue1.head.next.next
          queuePush(queue1, 40)
          queuePush(queue1, 50)
          console.log('new tail:', queue1.tail.value)
          printStructure(queue1)
          console.log('---')
          const queue2 = {
              head: null,
              tail: null
          }
          queuePush(queue2, 3)
          queuePush(queue2, 5)
          console.log('head:', queue2.head.value)
          console.log('tail:', queue2.tail.value)
          printStructure(queue2)
        `,
        output: `
          new tail: 50
          10
          20
          30
          40
          50
          ---
          head: 3
          tail: 5
          3
          5
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Удаление из очереди',
    text: 'Функция \\(queuePop\\). Дана очередь \\(a\\). Функция должна удалить первый элемент из очереди и вернуть его значение. Если его нет, вернуть null',
    note: null,
    examples: [
      {
        input: `
          const queue1 = {
              head: null,
              tail: null
          }
          console.log(queuePop(queue1))
          queuePush(queue1, 10)
          queuePush(queue1, 20)
          queuePush(queue1, 30)
          console.log(queuePop(queue1))
          console.log('tail:', queue1.tail.value)
          console.log(queuePop(queue1))
          console.log('tail:', queue1.tail.value)
          console.log(queuePop(queue1))
          console.log('tail:', queue1.tail)
        `,
        output: `
          null
          10
          tail: 30
          20
          tail: 30
          30
          tail: null
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Создание очереди',
    text: 'Напишите функцию \\(createQueue\\), которая ничего не принимает, возвращает пустую очередь, которая кроме head и tail имеет ещё методы \\(push\\) и \\(pop\\) (для добавления в конец и удаления из начала)',
    note: null,
    examples: [
      {
        input: `
          const queue1 = createQueue()
          queue1.push(10)
          queue1.push(20)
          console.log(queue1.pop())
          console.log(queue1.pop())
          console.log(queue1.pop())
          queue1.push(30)
          console.log(queue1.pop())
        `,
        output: `
          10
          20
          null
          30
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавить iterator',
    text: 'Измените функцию \\(createQueue\\), добавив метод Symbol.iterator для перебора всех элементов очереди',
    note: null,
    examples: [
      {
        input: `
          const queue1 = createQueue()
          queue1.push(10)
          queue1.push(20)
          queue1.push(30)
          console.log(...queue1)
          let sum = 0
          for (const x of queue1) {
              sum += x
          }
          console.log(sum)
        `,
        output: `
          10 20 30
          60
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Обработка инструкций',
    text: "Функция \\(handleActions\\). Дана последовательность \\(a\\), состоящая из строк с инструкциями. Есть только два типа инструкций: 'push {value}' и 'pop'. Первая должна добавлять в конец очереди \\(value\\), а вторая удалять из начала и выводить элемент из очереди",
    note: null,
    examples: [
      {
        input: `
          handleActions(seq('push A', 'push B', 'pop', 'push C', 'pop'))
          console.log('---')
          handleActions(seq('push abra cadabra', 'push 11 22 33', 'push 33 22 11', 'pop'))
        `,
        output: `
          A
          B
          ---
          abra cadabra
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Кассиры',
    text: "Функция \\(emulateCashiers\\). Дано \\(n\\) - количество кассиров, которые обрабатывают общий поток клиентов, а также последовательность инструкций \\(instructions\\). Есть три типа инструкций: 'new' - клиент пришёл в очередь, '{X} start' - кассир с номером X взял следующего клиента из очереди, '{X} end' - кассир X закончил обслуживать клиента. Выведите строки с номерами обслуженных клиентов (первая строка - номера обслуженных первым кассиром, вторая - вторым, и т. д.), последняя строка - номера клиентов, до сих пор не взятых в обслуживание",
    note: null,
    examples: [
      {
        input: `
          emulateCashiers(3, seq(
            'new', 'new', '1 start', 'new', '2 start',
            'new', '3 start', '2 end', 'new', '2 start',
            '1 end', '1 start', 'new', '3 end', 'new',
            '2 end', 'new'
          ))
          console.log('---')
          emulateCashiers(1, seq('new', '1 start', 'new'))
        `,
        output: `
          1
          2 4
          3
          6 7 8
          ---
          
          2
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в начало очереди',
    text: 'Добавить в \\(createQueue\\) метод \\(pushFirst\\), который добавляет элемент в начало очереди',
    note: null,
    examples: [
      {
        input: `
          const queue1 = createQueue()
          queue1.pushFirst(10)
          queue1.pushFirst(20)
          queue1.pushFirst(30)
          queue1.push(40)
          console.log(...queue1)
        `,
        output: `
          30 20 10 40
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Удаление из конца очереди',
    text: 'Добавить в \\(createQueue\\) метод \\(popLast\\), удаляющий элемент из конца очереди и возвращающий его значение. Если элемента нет, вернуть null',
    note: null,
    examples: [
      {
        input: `
          const queue1 = createQueue()
          queue1.push(10)
          console.log(queue1.popLast())
          console.log(queue1.popLast())
          queue1.push(20)
          queue1.push(30)
          queue1.push(40)
          console.log(queue1.popLast())
          console.log(...queue1)
        `,
        output: `
          10
          null
          40
          20 30
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Перевёрнутый вывод двусвязного списка',
    text: 'Функция \\(dequeReversePrint\\). Дан двусвязный список \\(a\\) (deque - double ended queue - двусторонняя очередь). Выведите его элементы в обратном порядке',
    note: null,
    examples: [
      {
        input: `
          const deque1 = {
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null,
                          prev: null
                      },
                      prev: null
                  },
                  prev: null
              },
              tail: null
          }
          deque1.head.next.prev = deque1.head
          deque1.head.next.next.prev = deque1.head.next
          deque1.tail = deque1.head.next.next
          dequeReversePrint(deque1)
        `,
        output: `
          30
          20
          10
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в начало дека',
    text: 'Функция \\(dequePushFirst\\). Дан дек \\(a\\) и число \\(x\\). Добавьте \\(x\\) в начало дека',
    note: null,
    examples: [
      {
        input: `
          const deque1 = {
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null,
                          prev: null
                      },
                      prev: null
                  },
                  prev: null
              },
              tail: null
          }
          deque1.head.next.prev = deque1.head
          deque1.head.next.next.prev = deque1.head.next
          deque1.tail = deque1.head.next.next

          dequePushFirst(deque1, 40)

          printStructure(deque1)
          console.log('---')
          dequeReversePrint(deque1)

          const deque2 = {
              head: null,
              tail: null
          }
          dequePushFirst(deque2, 5)
          dequePushFirst(deque2, 6)
          dequePushFirst(deque2, 7)
          console.log('---')
          printStructure(deque2)
          console.log('---')
          dequeReversePrint(deque2)
        `,
        output: `
          40
          10
          20
          30
          ---
          30
          20
          10
          40
          ---
          7
          6
          5
          ---
          5
          6
          7
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в конец дека',
    text: 'Функция \\(dequePushLast\\). Дан дек \\(a\\) и число \\(x\\). Добавьте \\(x\\) в конец дека',
    note: null,
    examples: [
      {
        input: `
          const deque1 = {
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null,
                          prev: null
                      },
                      prev: null
                  },
                  prev: null
              },
              tail: null
          }
          deque1.head.next.prev = deque1.head
          deque1.head.next.next.prev = deque1.head.next
          deque1.tail = deque1.head.next.next

          dequePushLast(deque1, 40)

          printStructure(deque1)
          console.log('---')
          dequeReversePrint(deque1)

          const deque2 = {
              head: null,
              tail: null
          }
          dequePushLast(deque2, 5)
          dequePushLast(deque2, 6)
          dequePushLast(deque2, 7)
          console.log('---')
          printStructure(deque2)
          console.log('---')
          dequeReversePrint(deque2)
        `,
        output: `
          10
          20
          30
          40
          ---
          40
          30
          20
          10
          ---
          5
          6
          7
          ---
          7
          6
          5
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Удаление из начала дека',
    text: 'Функция \\(dequePopFirst\\). Дан дек \\(a\\). Удалите его первый элемент и верните его значение. Если элемента нет, верните null',
    note: null,
    examples: [
      {
        input: `
          const deque1 = {
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null,
                          prev: null
                      },
                      prev: null
                  },
                  prev: null
              },
              tail: null
          }
          deque1.head.next.prev = deque1.head
          deque1.head.next.next.prev = deque1.head.next
          deque1.tail = deque1.head.next.next

          console.log(dequePopFirst(deque1))

          console.log('---')
          printStructure(deque1)
          console.log('---')
          dequeReversePrint(deque1)

          const deque2 = {
              head: null,
              tail: null
          }
          dequePushLast(deque2, 5)
          dequePushLast(deque2, 6)
          console.log('---')
          console.log(dequePopFirst(deque2))
          console.log(dequePopFirst(deque2))
          console.log(dequePopFirst(deque2))
          console.log('---')
          dequeReversePrint(deque2)
        `,
        output: `
          10
          ---
          20
          30
          ---
          30
          20
          ---
          5
          6
          null
          ---
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Удаление из конца дека',
    text: 'Функция \\(dequePopLast\\). Дан дек \\(a\\). Удалите его последний элемент и верните его значение. Если элемента нет, верните null',
    note: null,
    examples: [
      {
        input: `
          const deque1 = {
              head: {
                  value: 10,
                  next: {
                      value: 20,
                      next: {
                          value: 30,
                          next: null,
                          prev: null
                      },
                      prev: null
                  },
                  prev: null
              },
              tail: null
          }
          deque1.head.next.prev = deque1.head
          deque1.head.next.next.prev = deque1.head.next
          deque1.tail = deque1.head.next.next

          console.log(dequePopLast(deque1))

          console.log('---')
          printStructure(deque1)
          console.log('---')
          dequeReversePrint(deque1)

          const deque2 = {
              head: null,
              tail: null
          }
          dequePushLast(deque2, 5)
          dequePushLast(deque2, 6)
          console.log('---')
          console.log(dequePopLast(deque2))
          console.log(dequePopLast(deque2))
          console.log(dequePopLast(deque2))
          console.log('---')
          printStructure(deque2)
        `,
        output: `
          30
          ---
          10
          20
          ---
          20
          10
          ---
          6
          5
          null
          ---
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Создание дека',
    text: 'Функция \\(createDeque\\). Функция ничего не принимает, создаёт пустой дек и возвращает его, добавив кроме head: null и tail: null также методы \\(pushFirst\\), \\(pushLast\\), \\(popFirst\\), \\(popLast\\) (для добавления в начало и конец, и для удаления из начала и конца соответственно), а также добавить функциональность итератора',
    note: null,
    examples: [
      {
        input: `
          const deque1 = createDeque()
          deque1.pushFirst(10)
          deque1.pushFirst(20)
          deque1.pushLast(30)
          deque1.pushLast(40)
          console.log(...deque1)
          console.log(deque1.popFirst())
          console.log(deque1.popLast())
          console.log(...deque1)
        `,
        output: `
          20 10 30 40
          20
          40
          10 30
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Длина списка',
    text: 'Нужно изменить функцию \\(createDeque\\) таким образом, чтобы у возвращаемого объекта было поле \\(size\\), содержащее количество элементов в списке',
    note: null,
    examples: [
      {
        input: `
          const deque1 = createDeque()
          console.log(deque1.size)
          deque1.pushFirst(10)
          console.log(deque1.size)
          deque1.pushFirst(20)
          console.log(deque1.size)
          deque1.pushLast(30)
          console.log(deque1.size)
          deque1.pushLast(40)
          console.log(deque1.size)
          deque1.popFirst()
          console.log(deque1.size)
          deque1.popLast()
          console.log(deque1.size)
          deque1.popLast()
          console.log(deque1.size)
          deque1.popLast()
          console.log(deque1.size)
          deque1.popLast()
          console.log(deque1.size)
          deque1.popFirst()
          console.log(deque1.size)
        `,
        output: `
          0
          1
          2
          3
          4
          3
          2
          1
          0
          0
          0
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'forEach для дека',
    text: 'Нужно изменить \\(createDeque\\), чтобы возвращаемый объект имел метод \\(forEach\\). Должен работать аналогично \\(forEach\\) у массивов',
    note: null,
    examples: [
      {
        input: `
          const deque1 = createDeque()
          deque1.pushLast(10)
          deque1.pushLast(20)
          deque1.pushLast(30)
          let sumValues = 0
          let sumIndexes = 0
          let arg3IsDeque1 = true
          deque1.forEach((v, i, a) => {
              sumValues += v
              sumIndexes += i
              arg3IsDeque1 = arg3IsDeque1 && a === deque1
          })
          console.log(sumValues, sumIndexes, arg3IsDeque1)
        `,
        output: `
          60 3 true
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'map для дека',
    text: 'Нужно изменить \\(createDeque\\), чтобы возвращаемый объект имел метод \\(map\\). Должен работать аналогично \\(map\\) у массивов, только результатом будет дек, а не массив',
    note: null,
    examples: [
      {
        input: `
          const deque1 = createDeque()
          deque1.pushLast(10)
          deque1.pushLast(20)
          deque1.pushLast(30)
          console.log(...deque1.map(x => x * 2))
          console.log(...deque1.map((x, i) => x + i))
          console.log(...deque1.map((x, i, a) => x + i + a.size))
        `,
        output: `
          20 40 60
          10 21 32
          13 24 35
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'filter для дека',
    text: 'Нужно изменить \\(createDeque\\), чтобы возвращаемый объект имел метод \\(filter\\). Должен работать аналогично \\(filter\\) у массивов, только результатом будет дек, а не массив',
    note: null,
    examples: [
      {
        input: `
          const deque1 = createDeque()
          deque1.pushLast(14)
          deque1.pushLast(17)
          deque1.pushLast(19)
          deque1.pushLast(22)
          console.log(...deque1.filter(x => x % 2 === 0))
          console.log(...deque1.filter(x => [4, 7].includes(x % 10)))
          console.log(...deque1.filter((x, i) => i % 2 === 0))
          console.log(...deque1.filter((x, i, a) => i + a.size > 4))
        `,
        output: `
          14 22
          14 17
          14 19
          17 19 22
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'reduce для дека',
    text: 'Нужно изменить \\(createDeque\\), чтобы возвращаемый объект имел метод \\(reduce\\). Должен работать аналогично \\(reduce\\) у массивов',
    note: null,
    examples: [
      {
        input: `
          const deque1 = createDeque()
          deque1.pushLast(10)
          deque1.pushLast(11)
          deque1.pushLast(12)
          deque1.pushLast(13)
          console.log(deque1.reduce((acc, x) => acc + x))
          console.log(deque1.reduce((acc, x, i) => acc + i))
          console.log(deque1.reduce((acc, x, i) => acc + i, 0))
          console.log(deque1.reduce((acc, x, i, a) => acc + a.size, 0))
        `,
        output: `
          46
          16
          6
          16
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Вывод дерева (КЛП) рекурсивно',
    text: 'Функция \\(printTreeFromTopRec\\). Дано дерево \\(tree\\). Выведите все элементы с помощью рекурсии: сначала корень, потом рекурсивно левое поддерево, потом правое поддерево',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: {
                  value: 8,
                  left: {
                      value: 3,
                      left: {
                          value: 1,
                          left: null,
                          right: null
                      },
                      right: {
                          value: 6,
                          left: {
                              value: 4,
                              left: null,
                              right: null
                          },
                          right: {
                              value: 7,
                              left: null,
                              right: null
                          }
                      }
                  },
                  right: {
                      value: 10,
                      left: null,
                      right: {
                          value: 14,
                          left: {
                              value: 13,
                              left: null,
                              right: null
                          },
                          right: null
                      }
                  }
              }
          }
          printTreeFromTopRec(tree1)
        `,
        output: `
          8
          3
          1
          6
          4
          7
          10
          14
          13
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Вывод дерева (КЛП) стеком',
    text: 'Функция \\(printTreeFromTopStack\\). Дано дерево \\(tree\\). Выведите все элементы с помощью стека (не используя рекурсию) в порядке корень-лево-право (как в предыдущей задаче)',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: {
                  value: 8,
                  left: {
                      value: 3,
                      left: {
                          value: 1,
                          left: null,
                          right: null
                      },
                      right: {
                          value: 6,
                          left: {
                              value: 4,
                              left: null,
                              right: null
                          },
                          right: {
                              value: 7,
                              left: null,
                              right: null
                          }
                      }
                  },
                  right: {
                      value: 10,
                      left: null,
                      right: {
                          value: 14,
                          left: {
                              value: 13,
                              left: null,
                              right: null
                          },
                          right: null
                      }
                  }
              }
          }
          printTreeFromTopStack(tree1)
        `,
        output: `
          8
          3
          1
          6
          4
          7
          10
          14
          13
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Вывод дерева (ЛКП) рекурсивно',
    text: 'Функция \\(printTreeFromLeftRec\\). Дано дерево \\(tree\\). Выведите все элементы с помощью рекурсии: сначала левое поддерево, потом корень, потом правое поддерево',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: {
                  value: 8,
                  left: {
                      value: 3,
                      left: {
                          value: 1,
                          left: null,
                          right: null
                      },
                      right: {
                          value: 6,
                          left: {
                              value: 4,
                              left: null,
                              right: null
                          },
                          right: {
                              value: 7,
                              left: null,
                              right: null
                          }
                      }
                  },
                  right: {
                      value: 10,
                      left: null,
                      right: {
                          value: 14,
                          left: {
                              value: 13,
                              left: null,
                              right: null
                          },
                          right: null
                      }
                  }
              }
          }
          printTreeFromLeftRec(tree1)
        `,
        output: `
          1
          3
          4
          6
          7
          8
          10
          13
          14
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Вывод дерева (ЛКП) стеком',
    text: 'Функция \\(printTreeFromLeftStack\\). Дано дерево \\(tree\\). Выведите все элементы с помощью стека (не используя рекурсию) в порядке лево-корень-право (как в предыдущей задаче)',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: {
                  value: 8,
                  left: {
                      value: 3,
                      left: {
                          value: 1,
                          left: null,
                          right: null
                      },
                      right: {
                          value: 6,
                          left: {
                              value: 4,
                              left: null,
                              right: null
                          },
                          right: {
                              value: 7,
                              left: null,
                              right: null
                          }
                      }
                  },
                  right: {
                      value: 10,
                      left: null,
                      right: {
                          value: 14,
                          left: {
                              value: 13,
                              left: null,
                              right: null
                          },
                          right: null
                      }
                  }
              }
          }
          printTreeFromLeftStack(tree1)
        `,
        output: `
          1
          3
          4
          6
          7
          8
          10
          13
          14
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в дерево циклом',
    text: 'Функция \\(treeAddLoop\\). Дано дерево \\(tree\\) и число \\(x\\). Нужно добавить \\(x\\) в дерево. Если элемент уже существует, добавлять ещё раз не нужно. Сделать с помощью цикла',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: null
          }
          treeAddLoop(tree1, 8)
          treeAddLoop(tree1, 3)
          treeAddLoop(tree1, 10)
          treeAddLoop(tree1, 1)
          treeAddLoop(tree1, 6)
          treeAddLoop(tree1, 14)
          treeAddLoop(tree1, 4)
          treeAddLoop(tree1, 7)
          treeAddLoop(tree1, 13)
          treeAddLoop(tree1, 6)
          printTreeFromLeftRec(tree1)
        `,
        output: `
          1
          3
          4
          6
          7
          8
          10
          13
          14
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Добавление в дерево рекурсивно',
    text: 'Функция \\(treeAddRec\\). Дано дерево \\(tree\\) и число \\(x\\). Нужно добавить \\(x\\) в дерево. Если элемент уже существует, добавлять ещё раз не нужно. Сделать с помощью рекурсии',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: null
          }
          treeAddRec(tree1, 8)
          treeAddRec(tree1, 3)
          treeAddRec(tree1, 10)
          treeAddRec(tree1, 1)
          treeAddRec(tree1, 6)
          treeAddRec(tree1, 14)
          treeAddRec(tree1, 4)
          treeAddRec(tree1, 7)
          treeAddRec(tree1, 13)
          treeAddRec(tree1, 6)
          printTreeFromLeftRec(tree1)
        `,
        output: `
          1
          3
          4
          6
          7
          8
          10
          13
          14
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Поиск в дереве рекурсивно',
    text: 'Функция \\(treeContainsRec\\). Дано дерево \\(tree\\) и число \\(x\\). Функция должна вернуть true, если \\(x\\) есть в дереве, и false если нет, используя рекурсию',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: {
                  value: 8,
                  left: {
                      value: 3,
                      left: {
                          value: 1,
                          left: null,
                          right: null
                      },
                      right: {
                          value: 6,
                          left: {
                              value: 4,
                              left: null,
                              right: null
                          },
                          right: {
                              value: 7,
                              left: null,
                              right: null
                          }
                      }
                  },
                  right: {
                      value: 10,
                      left: null,
                      right: {
                          value: 14,
                          left: {
                              value: 13,
                              left: null,
                              right: null
                          },
                          right: null
                      }
                  }
              }
          }
          console.log(treeContainsRec(tree1, 7))
          console.log(treeContainsRec(tree1, 8))
          console.log(treeContainsRec(tree1, 0))
          console.log(treeContainsRec(tree1, 9))
          console.log(treeContainsRec(tree1, 15))
        `,
        output: `
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Поиск в дереве циклом',
    text: 'Функция \\(treeContainsLoop\\). Дано дерево \\(tree\\) и число \\(x\\). Функция должна вернуть true, если \\(x\\) есть в дереве, и false если нет, используя цикл',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: {
                  value: 8,
                  left: {
                      value: 3,
                      left: {
                          value: 1,
                          left: null,
                          right: null
                      },
                      right: {
                          value: 6,
                          left: {
                              value: 4,
                              left: null,
                              right: null
                          },
                          right: {
                              value: 7,
                              left: null,
                              right: null
                          }
                      }
                  },
                  right: {
                      value: 10,
                      left: null,
                      right: {
                          value: 14,
                          left: {
                              value: 13,
                              left: null,
                              right: null
                          },
                          right: null
                      }
                  }
              }
          }
          console.log(treeContainsLoop(tree1, 7))
          console.log(treeContainsLoop(tree1, 8))
          console.log(treeContainsLoop(tree1, 0))
          console.log(treeContainsLoop(tree1, 9))
          console.log(treeContainsLoop(tree1, 15))
        `,
        output: `
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Удаление из дерева',
    text: 'Функция \\(removeFromTree\\). Дано дерево \\(tree\\) и число \\(x\\). Функция должна удалить элемент \\(x\\) из дерева, если он существует. Нужно вернуть true, если элемент был удалён, и false, если не был',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              root: {
                  value: 8,
                  left: {
                      value: 3,
                      left: {
                          value: 1,
                          left: null,
                          right: null
                      },
                      right: {
                          value: 6,
                          left: {
                              value: 4,
                              left: null,
                              right: null
                          },
                          right: {
                              value: 7,
                              left: null,
                              right: null
                          }
                      }
                  },
                  right: {
                      value: 10,
                      left: null,
                      right: {
                          value: 14,
                          left: {
                              value: 13,
                              left: null,
                              right: null
                          },
                          right: null
                      }
                  }
              }
          }
          console.log(removeFromTree(tree1, 4))
          console.log(removeFromTree(tree1, 7))
          console.log(removeFromTree(tree1, 8))
          console.log(removeFromTree(tree1, 3))
          console.log(removeFromTree(tree1, 9))
          printTreeFromLeftRec(tree1)
        `,
        output: `
          true
          true
          true
          true
          false
          1
          6
          10
          13
          14
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Создание дерева',
    text: 'Реализовать функцию \\(createTree\\), возвращающую пустое дерево с методами add, contains, remove',
    note: null,
    examples: [
      {
        input: `
          const tree1 = createTree()
          tree1.add(8)
          tree1.add(3)
          tree1.add(10)
          tree1.add(1)
          tree1.add(6)
          tree1.add(14)
          tree1.add(4)
          tree1.add(7)
          tree1.add(13)
          tree1.add(6)
          console.log(tree1.contains(7))
          console.log(tree1.contains(8))
          console.log(tree1.contains(0))
          console.log(tree1.contains(9))
          console.log(tree1.contains(15))
          console.log('---')
          console.log(tree1.remove(4))
          console.log(tree1.remove(7))
          console.log(tree1.remove(8))
          console.log(tree1.remove(3))
          console.log(tree1.remove(9))
          printTreeFromLeftRec(tree1)
        `,
        output: `
          true
          true
          false
          false
          false
          ---
          true
          true
          true
          true
          false
          1
          6
          10
          13
          14
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Длина дерева',
    text: 'Изменить \\(createTree\\), чтобы возвращала дерево с полем \\(size\\), содержащим актуальную длину дерева',
    note: null,
    examples: [
      {
        input: `
          const tree1 = createTree()
          console.log(tree1.size)
          tree1.add(8)
          console.log(tree1.size)
          tree1.add(3)
          tree1.add(10)
          tree1.add(1)
          tree1.add(6)
          tree1.add(14)
          tree1.add(4)
          tree1.add(7)
          tree1.add(13)
          console.log(tree1.size)
          tree1.add(6)
          console.log(tree1.size)
          tree1.remove(4)
          tree1.remove(7)
          tree1.remove(8)
          tree1.remove(3)
          tree1.remove(9)
          console.log(tree1.size)
        `,
        output: `
          0
          1
          9
          9
          5
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'iterator для дерева (ЛКП)',
    text: 'Изменить \\(createTree\\), добавив в возвращаемый объект метод Symbol.iterator, перебирающий элементы дерева в порядке лево-корень-право',
    note: null,
    examples: [
      {
        input: `
          const tree1 = createTree()
          tree1.add(8)
          tree1.add(3)
          tree1.add(10)
          tree1.add(1)
          tree1.add(6)
          tree1.add(14)
          tree1.add(4)
          tree1.add(7)
          tree1.add(13)
          console.log(...tree1)
        `,
        output: `
          1 3 4 6 7 8 10 13 14
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'forEach для дерева (ЛКП)',
    text: 'Изменить \\(createTree\\), добавив в возвращаемый объект метод \\(forEach\\), работающий аналогично forEach у массивов',
    note: null,
    examples: [
      {
        input: `
          const tree1 = createTree()
          tree1.add(8)
          tree1.add(3)
          tree1.add(10)
          tree1.add(1)
          tree1.add(6)
          tree1.add(14)
          tree1.add(4)
          tree1.add(7)
          tree1.add(13)
          let count = 0
          tree1.forEach(() => {
              count++
          })
          console.log(count)
          tree1.forEach((v, i, a) => {
              console.log(v, i, a.size)
          })
        `,
        output: `
          9
          1 0 9
          3 1 9
          4 2 9
          6 3 9
          7 4 9
          8 5 9
          10 6 9
          13 7 9
          14 8 9
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сортировка деревом',
    text: 'Функция \\(treeSort\\). Дан массив \\(a\\), состоящий из чисел. Выведите уникальные элементы массива в строку в порядке возрастания',
    note: null,
    examples: [
      {
        input: `
          treeSort([5, 1, 3, 2, 4, 4, 2, 5])
          treeSort([9, 8, 4, 6, 2, 8, 5, 4, 6, 6])
        `,
        output: `
          1 2 3 4 5
          2 4 5 6 8 9
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Вывод небинарного дерева',
    text: 'Функция \\(printNTree\\). Дано небинарное дерево \\(tree\\). Выведите все значения элементов в порядке корень-потомки рекурсивно',
    note: null,
    examples: [
      {
        input: `
          const tree1 = {
              value: 6,
              children: [
                  {
                      value: 5,
                      children: []
                  },
                  {
                      value: 4,
                      children: [
                          {
                              value: 3,
                              children: [
                                  {
                                      value: 2
                                  }
                              ]
                          },
                          {
                              value: 1,
                              children: []
                          },
                          {
                              value: 0,
                              children: []
                          }
                      ]
                  },
              ]
          }
          printNTree(tree1)
        `,
        output: `
          6
          5
          4
          3
          2
          1
          0
        `
      }
    ]
  }
]
// хэш-таблицы??
